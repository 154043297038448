import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subject } from 'rxjs';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AuthService, LOCAL_STORAGE } from '@vantage-platform/auth';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
@Injectable()
export class TimeoutService implements OnDestroy {
    private readonly _destroyed$ = new Subject();
    idleCountdownSeconds: number;
    idleExtendedSeconds: number = 300; // 5 minutes of inactivity
    idleTimeoutSeconds: number = 300;

    idleState = "NOT_STARTED";
    countdown?: number = null;
    lastPing?: Date = null;


    private _idleInitTime: Date;
    private _idleCountdownStartTime: Date;


    constructor(
        private _idle: Idle,
        private _keepalive: Keepalive,
        private _auth: AuthService,
        private _router: Router
    ) {}

    ngOnDestroy(): void {
        this._destroyed$.next(true);
        this._destroyed$.complete();
    }

    initIdle(): void {
        if(this._auth.isLoggedIn) {
            this.idleCountdownSeconds = this._auth.sessionTimeout - this.idleTimeoutSeconds;


            if (!this._idle.isRunning()) {
                this._idleInitTime = new Date();
                this._idle.setIdle(this.idleTimeoutSeconds);
                this._idle.setTimeout(this.idleCountdownSeconds);
                this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
            }
        } else {
            // if user not signed out but idle is still active, user should be navigate to logout/login page
            if (this._idle?.isRunning()) {
                this.stopIdle();
                this._router.navigate(['/login']);
            }
        }

        this._idle.onTimeout.subscribe(() => {
        this._auth.logOut();
        });

        this._idle.onIdleStart.subscribe(() => {
            this._idleCountdownStartTime = new Date();

            if (this._isIdleExpired()) {
                this._auth.logOut();
            }
        });
        
        this._idle.onIdleEnd.subscribe(() => {
            // user active - reset state;
            this._auth.forceTokenRefresh();
            this.reset();
        });

        // sets the ping interval to 15 seconds
        this._keepalive.interval(15);

        this._keepalive.onPing.subscribe(() => this.lastPing = new Date());
    }

    stopIdle(): void {
        this._idle.stop();
    }

    public reset(): void {
        this.idleState = "NOT_IDLE";
        this.countdown = null;
        this.lastPing = null;
        this.idleCountdownSeconds = this._auth.sessionTimeout - this.idleTimeoutSeconds;; // get from auth service - as timeout configured via AAD
        this._idle.setTimeout(this.idleCountdownSeconds)
        this._idle.setIdle(this.idleExtendedSeconds); 
        this._idle.watch();
    }

    private _isIdleExpired(): boolean {
        const elapsedSeconds = (this._idleCountdownStartTime.getTime() - this._idleInitTime.getTime()) / 1000;
        return elapsedSeconds > (this.idleTimeoutSeconds + this.idleCountdownSeconds);
    }
}
