import { AppState, IInstance, setApp } from '@vantage-platform/store';
import { AuthService, isTeamsApp } from '@vantage-platform/auth';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { TeamsContextService } from '@vantage-platform/share-to-teams';
import gitInfo from './../../../git-version.json';

@Component({
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SiteLayoutComponent implements OnInit, OnDestroy {
  version = gitInfo.hash;

  instance: IInstance;

  profile: any = {};

  constructor(
    public auth: AuthService,
    private store: Store<AppState>,
    public msal: MsalService,
    public teamsCTX: TeamsContextService
  ) {}

  ngOnInit(): void {
    if (this.auth.isLoggedIn) {
      this.store.dispatch(setApp(undefined));
    }
  }

  ngOnDestroy(): void {}
}
