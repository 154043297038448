import {
  APPS,
  AppState,
  IApp,
  ISideNavItemModel,
  IXPagination,
  categories,
  getApps,
  setHeaderVisible,
} from '@vantage-platform/store';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  BoardState,
  CardsUiState,
} from '../+state/cards/cards-ui-state.reducer';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import {
  clearBoardParams,
  patchCardsItems,
  resetCardsState,
  setCardsItems,
  setCardsUIState,
  setEndOfPage,
  setIsLoadingState,
} from '../+state/cards/cards-ui-state.actions';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import {
  getAllCardsItems,
  getApiParams,
  getBoardDetails,
  getDynamicLocationName,
  getEndOfPage,
  getIsBoardEmpty,
  getIsLoading,
} from '../+state/cards/cards-ui-state.selectors';

import { CARDS_CONFIG } from './cards';
import { CardsService } from './cards.service';
import { FocusDataService } from '../services/focus-data.service';
import { HttpResponse } from '@angular/common/http';
import { ICard } from '../models/i-card';
import { ICardRenderer } from './i-cards';
import { NgxSpinnerService } from 'ngx-spinner';
import { PerfectScrollbarDirective } from 'ngx-om-perfect-scrollbar';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';

@Component({
  selector: 'vantage-platform-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent implements OnInit, OnDestroy {
  @Input() config: ICardRenderer;
  @Input() useNativeScroll = false;
  @Input() disableScroll: BehaviorSubject<boolean> = new BehaviorSubject(false); // set default value if input property missing

  private subscriptions: Subscription[] = [];
  private paramsSnapshot: Params = {};

  page = 1;
  endOfPage: boolean;
  scrollDistance = 1;
  scrollUpDistance = 1;
  throttle = 300;
  loading = true;
  cards: ICard[] = [];
  apps: IApp[] = [];
  boardId: number;
  $apps: Observable<IApp[]>;
  $cards: Observable<ICard[]>;
  boardDetails: BoardState;
  $isLoading: Observable<boolean>;
  $isEndOfPage: Observable<boolean>;
  $isBoardEmpty: Observable<boolean>;
  app: IApp;

  isCustomSpinnerExist: boolean;

  @ViewChild(PerfectScrollbarDirective)
  directiveRef?: PerfectScrollbarDirective;

  constructor(
    public cardService: CardsService,
    private appStore: Store<AppState>,
    private store: Store<CardsUiState>,
    private focusDS: FocusDataService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.$apps = this.appStore.select(getApps);
    this.$cards = this.store
      .select(getAllCardsItems)
      .pipe(distinctUntilChanged(isEqual));
    this.$isLoading = this.store.select(getIsLoading);
    this.$isEndOfPage = this.store.select(getEndOfPage);
    this.$isBoardEmpty = this.store.select(getIsBoardEmpty);
    this.app = this.activeRoute.snapshot.data.app;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select(getApiParams).subscribe((p) => {
        this.paramsSnapshot = this.activeRoute.snapshot;
        if (p && p.params) {
          this.directiveRef?.update();

          if (+p.params.page === 1) {
            this.store.dispatch(resetCardsState());
            this.store.dispatch(setHeaderVisible({ isHeaderVisible: true }));
          }
          this.page = +p.params.page || 1;
          this.boardId = this.app.defaultBoardID;
          this.getFocusData(p.params, p.boardId);
        }
      })
    );

    if (this.isProfilesApp()) this.getDynamicLocation();

    this.subscriptions.push(
      this.$cards.subscribe((c) => {
        this.cards = c;
        this.directiveRef?.update();
      })
    );

    this.subscriptions.push(
      this.$isEndOfPage.subscribe((e) => {
        this.endOfPage = e;
      })
    );

    this.subscriptions.push(
      this.store.select(getBoardDetails).subscribe((v) => {
        this.boardDetails = v;
      })
    );

    this.subscriptions.push(
      this.spinner.getSpinner('focus-spinner').subscribe((s) => {
        console.log(s);
        this.isCustomSpinnerExist = s.show;
      })
    );

    this.subscriptions.push(this.$apps.subscribe((a) => (this.apps = a)));
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearBoardParams());
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getCardConfig(card: ICard) {
    if (card.appID) {
      return CARDS_CONFIG[card.appID](card);
    }
  }

  onScrollDown() {
    if (
      !this.boardDetails.isLoading &&
      !this.endOfPage &&
      !this.boardDetails.boardEmpty
    ) {
      this.page += 1;
      const params = this.activeRoute.queryParams['value'];
      const newParams = { ...params, page: this.page };
      this.router.navigate(['./'], {
        relativeTo: this.activeRoute,
        queryParams: newParams,
        replaceUrl: true,
      });
    }
  }

  onScroll(isHeaderVisible: boolean) {
    this.store.dispatch(setHeaderVisible({ isHeaderVisible }));
  }
  private isProfilesApp(): boolean {
    return this.app.name.toLowerCase() === APPS.profiles;
  }

  private getDynamicLocation(): void {
    this.subscriptions.push(
      this.store
        .select(getDynamicLocationName)
        .pipe(filter((v) => !!v))
        .subscribe((a) => {
          this.cardService.dynamicLocationName = a;
          this.getFocusData({ page: 1 }, this.boardId);
        })
    );
  }

  private getFocusData(params, boardId) {
    if (+params.page === 1) {
      this.store.dispatch(setCardsItems({ payload: [] }));
    }
    this.store.dispatch(setIsLoadingState({ payload: true }));

    this.focusDS
      .getFocusData(params, boardId)
      .subscribe((response: HttpResponse<ICard[]>) => {
        this.spinner.hide('focus-spinner');
        const pagination = JSON.parse(
          response.headers.get('X-Pagination')
        ) as IXPagination;
        let newCards = response.body || [];

        if (this.isProfilesApp()) {
          newCards = this.filterCardsCategoriesSelectedForProfiles(newCards);
        }

        if (!pagination || pagination.PageNumber === 1) {
          this.store.dispatch(
            setCardsUIState({
              payload: {
                cards: newCards,
                isLoading: false,
                loaded: true,
                boardEmpty: newCards.length === 0,
                endOfPage:
                  (pagination && pagination.PageNumber === pagination.TotalPages) ||
                  false,
                dynamicLocationName: this.cardService.dynamicLocationName,
              } as CardsUiState,
            })
          );
        } else {
          this.store.dispatch(
            setEndOfPage({
              payload:
                pagination && pagination.PageNumber === pagination.TotalPages,
            })
          );
          this.store.dispatch(patchCardsItems({ payload: newCards }));
        }

        this.endOfPage =
          pagination && pagination.PageNumber === pagination.TotalPages;
      });
  }

  private filterCardsCategoriesSelectedForProfiles(cards: ICard[]): ICard[] {
    const selectedCards: ICard[] = [];
    let ct: ISideNavItemModel[];
    this.appStore
      .select(categories)
      .pipe(take(1))
      .subscribe((values) => (ct = values));

    const areas = ct.filter((a) => a.isSelected);
    if (areas.length === 0) return cards;

    selectedCards.push(...cards.filter((b) => b.cardHeading === 'Key Facts'));

    areas.forEach((a) => {
      selectedCards.push(
        ...cards.filter(
          (b) => b.indicator === a.title && b.cardHeading !== 'Key Facts'
        )
      );
    });

    return selectedCards;
  }

  getCardContainerClass(): string {
    return this.isProfilesApp() ? 'card-container' : 'cards-container';
  }

  getEmptyCardContainerClass(): string {
    return 'empty-container';
  }

  trackCardBy: TrackByFunction<ICard> = (index: number, item: ICard) =>
    `${item.appID} ${item.categoryID} ${item.questionID} ${item.cardIntentID} ${item.id}`;

  oldOffset = 0;
  onNativeScroll($event: Event, el: HTMLDivElement) {
    if (el.offsetTop === this.oldOffset && el.offsetTop === 0) {
      this.onScroll(true);
    } else {
      this.onScroll(false);
    }
  }
}
