import {
  APPS,
  APPS_MAP,
  AppState,
  IApp,
  ICollection,
  collections,
  getApps,
  resetCategories,
} from '@vantage-platform/store';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ICard, ICardConfig } from '../models/i-card';
import { Injectable, InjectionToken, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  Share2TeamsComponent,
  TeamsContextService,
} from '@vantage-platform/share-to-teams';
import { Store, select } from '@ngrx/store';
import { analyticsCardMapConfig, analyticsCardTableAUConfig } from './cards';
import { catchError, map, take } from 'rxjs/operators';
import {
  removeCardItem,
  updateCardsItem,
} from '../+state/cards/cards-ui-state.actions';

import { CardDataService } from '../services/card-data.service';
import { CollectionsCrudComponent } from '@vantage-platform/common-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { InsightService } from '@vantage-platform/app-insight';
import { NgxSpinnerService } from 'ngx-spinner';

export const CARDS_UI_SERVICE = new InjectionToken<ICardService>(
  'CARDS_UI_SERVICE'
);

export interface ICardServiceConfig {
  isWebApp: boolean;
}

export interface ICardService {
  config: ICardServiceConfig;
  $user_collections: Observable<any[]>;
  getCard(cardId: number, focusBoardId: number): Observable<ICard>;
  setFocusCard(card: ICard, config: ICardConfig): void;
  getCardByGeoLocation(
    cardId: number,
    focusBoardId: number,
    geoLocation: string
  ): Observable<ICard>;
  share2teams(elem: HTMLElement, card: ICard): void;
  pinCardToCollecton(card: ICard, collectionId: number): Promise<any>;
  unPinCardFromCollecton(card: ICard, collectionId: number): Promise<any>;
  createCollection(): void;
}

@Injectable()
export class CardsService implements ICardService {
  $user_collections: Observable<ICollection[]>;
  bsModalRef: BsModalRef;
  config: ICardServiceConfig;
  dynamicLocationName: string;

  constructor(
    private store: Store<AppState>,
    public cardDS: CardDataService,
    public modalService: BsModalService,
    private router: Router,
    private ngZone: NgZone,
    private track: InsightService,
    private route: ActivatedRoute,
    private teamsCtx: TeamsContextService,
    private spinner?: NgxSpinnerService
  ) {
    this.$user_collections = store.pipe(select(collections));
    this.config = {
      isWebApp: true,
    };
  }

  getCardByGeoLocation(
    cardId: number,
    focusBoardId: number,
    geoLocation: string
  ): Observable<ICard> {
    return this.cardDS
      .getCardByGeoLocation(cardId, focusBoardId, geoLocation)
      .pipe(
        catchError(
          (r: HttpErrorResponse): Observable<undefined> => of(undefined)
        ),
        map((r: ICard): ICard | undefined => {
          if (r && 'id' in r) {
            // if HTTP 204 then there is no object
            this.store.dispatch(updateCardsItem({ payload: r }));
            return r;
          } else {
            this.store.dispatch(
              removeCardItem({ id: cardId, forceRefresh: false })
            );
          }
        })
      );
  }

  getCard(cardId: number, focusBoardId: number): Observable<ICard | undefined> {
    return this.cardDS.getCard(cardId, focusBoardId).pipe(
      catchError(
        (r: HttpErrorResponse): Observable<undefined> => of(undefined)
      ),
      map((r: ICard): ICard | undefined => {
        if (r && 'id' in r) {
          // if HTTP 204 then there is no object
          this.store.dispatch(updateCardsItem({ payload: r }));
          return r;
        } else {
          this.store.dispatch(
            removeCardItem({ id: cardId, forceRefresh: false })
          );
        }
      })
    );
  }

  setFocusCard(card: ICard, config: ICardConfig) {
    let apps: IApp[];
    this.store
      .select(getApps)
      .pipe(take(1))
      .subscribe((a) => {
        apps = a;
      });

    const cardApp = apps.find((a) => a.id === card.appID);
    const appName = cardApp.name.toLowerCase();

    if (appName.includes('focus')) {
      this.focusCard(card, APPS_MAP.get(APPS['focus']));
    } else if (appName.includes('analytics')) {
      this.navigateToReport(card, APPS_MAP.get(APPS['analytics']), config);
    } else if (appName.includes('work')) {
      this.navigateToWork(card, APPS_MAP.get(APPS['work']), config);
    }
     else if (appName.includes('profiles')) {
      //TODO: profile navigation
    }
  }

  share2teams(elem: HTMLElement, card: ICard): void {
    if (this.teamsCtx.msTeams) {
      const subEntityPath =
        window.location.pathname +
        '?card=' +
        card.id +
        '&page=1&period=' +
        card.periodType;
      console.log('%c ' + subEntityPath, 'background: #fff; color: blue');
      this.teamsCtx.msTeams.pages.shareDeepLink({
        subPageId: subEntityPath.split('&').join(';'),
        subPageLabel: card.question,
        subPageWebUrl: window.location.origin + subEntityPath,
      });
    } else {
      this.ngZone.run(() => {
        this.bsModalRef = this.modalService.show(Share2TeamsComponent, {
          class:
            'modal-dialog-centered modal-md allign-footer-right no-border__mobile',
        });
        this.bsModalRef.content['elem'] = elem;
        this.bsModalRef.content['card'] = card;
      });
    }
  }

  createCollection() {
    this.track.event('openCreateCollectionModal');

    this.bsModalRef = this.modalService.show(CollectionsCrudComponent, {
      class: 'modal-dialog-centered modal-md allign-footer-right',
    });
  }

  pinCardToCollecton(card: ICard, collectionId: number) {
    this.track.event('pinCardToCollection', {
      cardId: card.id,
      collectionId,
    });
    return this.cardDS.pinCard(card.id, collectionId).toPromise();
  }

  unPinCardFromCollecton(card: ICard, collectionId: number) {
    this.track.event('unPinCardFromCollection', {
      cardId: card.id,
      collectionId,
    });
    return this.cardDS.unPinCard(card.id, collectionId).toPromise();
  }

  private focusCard(card: ICard, appConfig) {
    this.spinner.show('focus-spinner');
    this.store.dispatch(resetCategories());

    this.track.event('setFocusCard', {
      cardId: card.id,
      categoryId: card.categoryID,
      cardTitle: card.title,
      question: card.question,
      questionId: card.questionID,
    });

    this.ngZone.run(() =>
      this.router.navigate([`${card.instanceID}/${appConfig.path}`], {
        queryParams: {
          card: card.id,
          period: card.periodType,
          page: 1,
        },
      })
    );
  }

  private navigateToReport(card: ICard, appConfig, cardConfig: ICardConfig) {
    if (cardConfig.type === analyticsCardTableAUConfig.type) {
      window.open(cardConfig.config.url);
      return;
    }

    const insightsEventName = `view${cardConfig.type
      .charAt(0)
      .toUpperCase()}${cardConfig.type.slice(1)}`;
    this.track.event(insightsEventName, {
      cardId: card.id,
      categoryId: card.categoryID,
      cardTitle: card.title,
      question: card.question,
      questionId: card.questionID,
    });

    const path =
      cardConfig.type === analyticsCardMapConfig.type
        ? appConfig.appConfig.mapPath
        : appConfig.appConfig.reportPath;

    this.ngZone.run(() =>
      this.router.navigate([
        `${card.instanceID}/${path}/${card.boardId}/${card.id}`,
      ])
    );
  }

  private navigateToWork(card: ICard, appConfig, cardConfig: ICardConfig) {
    this.track.event('startWorkflow', {
      cardId: card.id,
      categoryId: card.categoryID,
      cardTitle: card.title,
      question: card.question,
      questionId: card.questionID,
    });

    this.ngZone.run(() =>
      this.router.navigate([
        `${card.instanceID}/${appConfig.path}/view/${card.boardId}/${card.id}`,
      ])
    );
  }
}
