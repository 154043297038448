import {
  AppState,
  IInstance,
  assistantEnabled,
  instance,
  user,
} from '@vantage-platform/store';
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  Activity,
  ConnectionStatus,
  DirectLine,
  Message,
} from 'botframework-directlinejs';

import { Marked } from 'marked-ts';

import { AuthService } from '@vantage-platform/auth';
import { InsightService } from '@vantage-platform/app-insight';
import { MsalService } from '@azure/msal-angular';
import {
  Observable,
  Subject,
  lastValueFrom,
  map,
  takeUntil,
} from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from 'apps/vantage-focus/src/environments/environment';
import { IMessage } from './interfaces/i-message';
import { IChannelData } from './interfaces/i-channel-data';
import { AssistantDataService } from './assistant-data.service';
import { AuthenticationResult } from '@azure/msal-common';

const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return (
      navigator['userLanguage'] ||
      navigator.language ||
      navigator['browserLanguage'] ||
      'en-US'
    );
  }
};

@Component({
  selector: 'vp-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.scss'],
  encapsulation: ViewEncapsulation.None,
  })
export class AssistantComponent implements OnInit {
  instance: IInstance;
  profile: any = {};
  isActive: boolean;
  $assistantEnabled: Observable<boolean>;
  locale: string = getNavigatorLanguage();
  conversationId: string;

  messages: IMessage[] = [];
  typing: boolean;
  directLine: DirectLine;
  channelData: IChannelData;

  _subscriptions: any[] = [];
  destroy$ = new Subject<void>();

  private readonly conversationIdKeyPart = 'MSAL_DirectLine_Bot_ConversationId';
  // private readonly conversationWatermark = 'MSAL_DirectLine_Bot_Watermark';

  // private set watermark(val: string) {
  //   localStorage.setItem(
  //     `${this.conversationWatermark}_${this.instance.id}`, val
  //   )
  // }

  // private get watermark() {
  //   return localStorage.getItem(
  //     `${this.conversationWatermark}_${this.instance.id}`
  //   ) || null;
  // }


  @ViewChild('messagesList') private msList: ElementRef;

  constructor(
    public auth: AuthService,
    public msal: MsalService,

    private dataService: AssistantDataService,
    private store: Store<AppState>,
    private track: InsightService,
    private router: Router
  ) {

    this.store
      .select(instance)
      .pipe(
        takeUntil(this.destroy$),
        map((i) => ({ ...i }))
      )
      .subscribe((ins) => {
        this.instance = ins;
        this.messages = [];

        if (ins?.config?.data.assistantEnabled) {
          this.initBot();
        }
      });
    
    this.store.select(user).pipe(takeUntil(this.destroy$)).subscribe(u => {
      const { firstname, surname, identityProviderUserID } = u;
      this.profile = {
        name: `${firstname} ${surname}`,
        email: identityProviderUserID
      };
    })
    this.$assistantEnabled = this.store.select(assistantEnabled);
  }

  async ngOnInit() {
    const token = lastValueFrom(
      this.msal.acquireTokenSilent({
        scopes: environment.aad_config.access_scopes,
      })
    ).catch((e) => {
      console.log('Auth error', e);
      this.auth.logOut();
      return {} as AuthenticationResult;
    });

    this.channelData = {
      id: this.profile.email,
      token: (await token).accessToken,
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getMessageClassExt(message: IMessage) {
    const isAnswer = message.from.id === this.profile.email;
    return isAnswer ? 'question' : 'answer';
  }

  public toggleAssistant() {
    this.isActive = !this.isActive;
  }

  public sentPrediction(text: string) {
    if (!this.typing) {
      this.sendMessage(text);
    }
  }

  public getMarkdown(text: string) {
    return Marked.parse(text);
  }

  private initBot() {
    this.messages = [];

    this.conversationId =
      localStorage.getItem(
        `${this.conversationIdKeyPart}_${this.instance.id}`
      ) || null;

    const directLineSecret = this.instance.config?.data?.directLineSecretKey;

    this.directLine = new DirectLine({
      secret: directLineSecret,
      // watermark: this.watermark,
      conversationId: this.conversationId,
    });

    // Initialize the bot connection direct line
    let checkHistory = true;
    this._subscriptions.push(
      this.directLine.connectionStatus$.subscribe((connectionStatus) => {
        switch (connectionStatus) {
        // Successfully connected to the converstaion.
        case ConnectionStatus.Online:
          if (!this.conversationId) {
            checkHistory = false;
            localStorage.setItem(
              `${this.conversationIdKeyPart}_${this.instance.id}`,
              this.directLine['conversationId']
            );
          } else if (checkHistory) {
            this.getHistory(
              `${this.directLine['domain']}/conversations/${this.directLine['conversationId']}/activities`//?watermark=${this.watermark}`
            );
            checkHistory = false;
          }

          break;
        case ConnectionStatus.ExpiredToken:
          this.reconect();
          break;
        case ConnectionStatus.FailedToConnect:
          console.log(connectionStatus);
          this.resetBotInfo();
          break;
        }
      })
    );

    this._subscriptions.push(
      this.directLine.activity$.subscribe((activity: Activity) => {
        this.processActivity(activity);
      })
    );
  }

  private async reconect() {
    const token = await lastValueFrom(
      this.msal.acquireTokenSilent({
        scopes: environment.aad_config.access_scopes,
      })
    ).catch((e) => {
      console.log('Auth error', e);
      this.auth.logOut();
      return {} as AuthenticationResult;
    });
    this.directLine.reconnect({
      conversationId: this.conversationId,
      token: token.accessToken,
    });
  }

  public explore(card: any) {
    this.track.event('exploreInFocus', {
      cardId: card.id,
      periodType: card.periodType,
    });
    this.router.navigate([`${this.instance.id}${'/focus'}`], {
      queryParams: { card: card.id, period: card.periodType, page: 1 },
    });
  }

  public sendMessage(message: string) {
    const msg = {
      from: { id: this.profile.email },
      type: 'message',
      text: message,
      channelData: this.channelData,
    };
    this.addToMessages({
      ...msg,
      timestamp: new Date().toISOString(),
    } as IMessage);
    this.directLine.postActivity(msg as Message).subscribe(
      (id) => {
        console.log('Posted activity, assigned ID ', id);
      },
      (error) => console.log('Error posting activity', error)
    );
  }

  public replaceMessages(message: IMessage) {
    const index = this.messages.findIndex((m) => !m.id);

    if (~index) {
      this.messages[index] = {
        ...message,
        date: new Date(
          message.localTimestamp ? message.localTimestamp : message.timestamp
        ),
      };
    } else {
      this.addToMessages(message);
    }
    this.scrollToBot();
  }

  private addToMessages(message: IMessage, card = null) {
    this.messages = [
      ...this.messages,
      {
        ...message,
        date: new Date(
          message.localTimestamp ? message.localTimestamp : message.timestamp
        ),
        card,
      },
    ].sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
  }

  private scrollToBot() {
    const msListElement = this.msList.nativeElement;
    try {
      setTimeout(() => {
        msListElement.scroll({
          top: msListElement.scrollHeight,
          behavior: 'smooth',
        });
      }, 0);
    } catch (err) {}
  }

  private processActivity(activity: Activity) {
    if (activity.type === 'typing') {
      this.typing = true;
    } else if (activity.type === 'message') {
      const message = activity as Message;
      if (message.from.id === this.profile.email) {
        this.replaceMessages(message);
      } else if (
        !message.channelData ||
        (message.channelData && !message.channelData.focusChannelData)
      ) {
        this.typing = false;
        this.addToMessages(message, null);
      } else {
        lastValueFrom(
          this.dataService.getCardId(
            message.channelData.focusChannelData,
            this.instance
          )
        ).then((r) => {
          this.addToMessages(message, r ? r[0] : r);
          this.typing = false;
          this.scrollToBot();
        });
      }
    }
    // this.watermark = this.directLine
    this.scrollToBot();
  }

  private async getHistory(botUrl) {
    try {
      const data = (await this.dataService.getHistory(botUrl)) as any;

      if (data && data['activities'].length) {
        [...data['activities']].forEach((a) => this.processActivity(a));
      } else {
        this.resetBotInfo();
      }
    } catch {
      this.resetBotInfo();
    }
  }

  private resetBotInfo() {
    this.unsubscribe();
    localStorage.removeItem(
      `${this.conversationIdKeyPart}_${this.instance.id}`
    );
    this.initBot();
  }

  private unsubscribe() {
    this._subscriptions.map((s) => s.unsubscribe());
  }
}
