import * as fromCardsUiState from './+state/cards/cards-ui-state.reducer';

import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
  Type,
} from '@angular/core';
import { FilterExcludePipe, FilterIncludePipe } from './pipes/filter.pipe';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-om-perfect-scrollbar';

import { AddToCollectionComponent } from './components/add-to-collection/add-to-collection.component';
import { BulletListComponent } from './tiles/bullet-list/bullet-list.component';
import { CardComponent } from './components/card/card.component';
import { CardHeaderComponent } from './components/card-header/card-header.component';
import { CardRowComponent } from './components/card-row/card-row.component';
import { CardSkeletonComponent } from './components/card-skeleton/card-skeleton.component';
import { CardsComponent } from './cards/cards.component';
import { CardsUiStateEffects } from './+state/cards/cards-ui-state.effects';
import { CategoryTextComponent } from './tiles/category-text/category-text.component';
import { CommonModule } from '@angular/common';
import { CommonUiModule } from '@vantage-platform/common-ui';
import { CustomThousandSeparatorPipe } from './pipes/custom-thousand-separator.pipe';
import { DualBarChartComponent } from './tiles/dual-bar-chart/dual-bar-chart.component';
import { EffectsModule } from '@ngrx/effects';
import { FocusDataService } from './services/focus-data.service';
import { ICardService } from './cards/cards.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { KpiComponent } from './tiles/kpi/kpi.component';
import { LineChartComponent } from './tiles/line-chart/line-chart.component';
import { NgxApexchartsModule } from 'ngx-apexcharts';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { QuestionComponent } from './components/question/question.component';
import { RadialBarChartComponent } from './tiles/radial-bar-chart/radial-bar-chart.component';
import { RevardComponent } from './components/revard/revard.component';
import { ShareToTeamsModule } from '@vantage-platform/share-to-teams';
import { StackedBarChartComponent } from './tiles/stacked-bar-chart/stacked-bar-chart.component';
import { StoreModule } from '@ngrx/store';
import { TableComponent } from './tiles/table/table.component';
import { TextComponent } from './tiles/text/text.component';
import { TextListComponent } from './tiles/text-list/text-list.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UrlComponent } from './tiles/url/url.component';
import { EmptyComponent } from './components/empty/empty.component';
import { CaughtUpComponent } from './components/caught-up/caught-up.component';
import { DynamicComponent } from './tiles/dynamic/dynamic.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export interface CardsUiConfig {
  cardsService: Type<ICardService>;
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCardsUiState.CARDSUISTATE_FEATURE_KEY,
      fromCardsUiState.reducer
    ),
    EffectsModule.forFeature([CardsUiStateEffects]),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    PerfectScrollbarModule,
    ShareToTeamsModule,
    InfiniteScrollModule,
    NgxApexchartsModule,
    CommonUiModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [
    CardsComponent,
    CardComponent,
    QuestionComponent,
    CardHeaderComponent,
    RevardComponent,
    CardRowComponent,
    AddToCollectionComponent,
    FilterIncludePipe,
    FilterExcludePipe,
    LineChartComponent,
    CardSkeletonComponent,
    TableComponent,
    BulletListComponent,
    TextComponent,
    CustomThousandSeparatorPipe,
    UrlComponent,
    KpiComponent,
    RadialBarChartComponent,
    DualBarChartComponent,
    StackedBarChartComponent,
    CategoryTextComponent,
    TextListComponent,
    EmptyComponent,
    CaughtUpComponent,
    DynamicComponent,
  ],
  exports: [
    CardsComponent,
    CardComponent,
    BulletListComponent,
    TextComponent,
    CustomThousandSeparatorPipe,
    UrlComponent,
    KpiComponent,
    RadialBarChartComponent,
    DualBarChartComponent,
    StackedBarChartComponent,
    CategoryTextComponent,
    TextListComponent,
    DynamicComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class CardsUiModule {}
