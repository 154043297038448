import { ICard, ICardConfig, ITile, IUrlTile } from '../models/i-card';

const focusCardConfig: ICardConfig = {
  focusButtonTitle: 'FOCUS INSIGHT',
  indicatorClass: 'focus',
  type: 'focusCard',
  showButton: false,
};

const analyticsCardReportConfig: ICardConfig = {
  focusButtonTitle: 'View Report',
  linkTitle: 'View in Analytics',
  indicatorClass: 'analytics',
  type: 'report',
  showButton: true,
};

export const analyticsCardTableAUConfig: ICardConfig = {
  focusButtonTitle: 'View Report',
  linkTitle: 'View in Analytics',
  indicatorClass: 'analytics',
  type: 'tableau',
  showButton: true,
};

export const analyticsCardMapConfig: ICardConfig = {
  focusButtonTitle: 'View Map',
  indicatorClass: 'analytics',
  type: 'map',
  showButton: true,
};

// TODO: update during profile integration
const profilesCardConfig = {
  focusButtonTitle: '',
  indicatorClass: 'profiles',
  type: 'map',
  showButton: true,
};

export const workCardConfig: ICardConfig = {
  focusButtonTitle: 'Start Workflow',
  indicatorClass: 'work',
  type: 'work',
  showButton: true,
};

export let getFocusCardConfig = (card: ICard): ICardConfig => {
  return focusCardConfig;
};

export let getAnalyticsCardConfig = (card: ICard): ICardConfig => {
  let config = analyticsCardReportConfig;
  card?.rows?.find((r) =>
    r.tiles.find((t: ITile) => {
      // check only IURL tile
      if (t.cardTileTypeID === 8) {
        const data: IUrlTile = t.data as IUrlTile;

        switch (data?.config?.reportType.toLowerCase()) {
          case 'covidmap': {
            config = analyticsCardMapConfig;
            break;
          }
          case 'externallink': {
            config = { ...analyticsCardTableAUConfig, config: data.config };
            break;
          }
          default: {
            config = analyticsCardReportConfig;
          }
        }

        return true;
      }
    })
  );
  return config;
};

export let getProfilesCardConfig = (card: ICard): ICardConfig => {
  return profilesCardConfig;
};

export let getWorkCardConfig = (card: ICard): ICardConfig => {
  return workCardConfig;
};

export const CARDS_CONFIG = {
  1: getFocusCardConfig,
  2: getAnalyticsCardConfig,
  3: getProfilesCardConfig,
  5: getWorkCardConfig,
};

export let getConfigByAppId = (id: number): ICardConfig => {
  return CARDS_CONFIG[id]();
};
