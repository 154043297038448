import {
  AppState,
  ICard,
  IFocusBoard,
  IParams,
  getCurrentApp,
  instance,
} from '@vantage-platform/store';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from 'apps/vantage-focus/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FocusDataService {
  constructor(private http: HttpClient, public store: Store<AppState>) {}

  getFocusData(
    parameters: IParams,
    bordId: number
  ): Observable<HttpResponse<ICard[]>> {
    const FOCUS_URL = `me/boards/${bordId}/cards`;

    return this.http.get(`${environment.api.url}${FOCUS_URL}`, {
      params: this.mapCardsParams(parameters),
      observe: 'response',
    }) as Observable<HttpResponse<ICard[]>>;
  }

  getFocusBoard(cardId: number): Observable<IFocusBoard> {
    const params = {
      'board-card-id': cardId.toString(),
    };

    return this.http.get(
      `${environment.api.url}me/boards/focus-board/${cardId}`
    ) as Observable<IFocusBoard>;
  }

  validateFocusBoardAccess(
    cardId: number
  ): Observable<HttpResponse<IFocusBoard>> {
    return this.http.get(
      `${environment.api.url}me/boards/focus-board/${cardId}`,
      {
        observe: 'response',
      }
    ) as Observable<HttpResponse<IFocusBoard>>;
  }

  getDefaultBoardForCurrentApp() {
    let inst;
    let appId;

    this.store
      .select(instance)
      .pipe(take(1))
      .subscribe((values) => {
        inst = values;
      });

    this.store.select(getCurrentApp).subscribe((a) => {
      appId = a.id;
    });

    return this.http.get(
      `${environment.api.url}me/instance-app-default-board/${inst.id}:${appId}`
    );
  }

  private mapCardsParams(parameters) {
    enum PARAMS_KEY_MAP {
      'period' = 'period-type',
      'area' = 'category-id',
    }
    const params = {};

    this.excludeProps(Object.keys(parameters)).map((k) => {
      if (parameters[k]) {
        if (PARAMS_KEY_MAP[k]) {
          params[PARAMS_KEY_MAP[k]] = parameters[k];
        } else {
          params[k] = parameters[k];
        }
      }
    });

    return params;
  }

  private excludeProps(keys: string[]) {
    const exclude = ['card'];

    return keys.filter((k) => !exclude.includes(k));
  }
}
